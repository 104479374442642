import React from 'react';

import { withStyles, Typography, Switch } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import Tooltip from '@libComponents/Tooltip';

const styles = ({ spacing, typography }) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    switch: {
        padding: 0
    },
    label: {
        fontSize: typography.caption.fontSize
    },
    infoIcon: {
        marginLeft: spacing.unit * 0.5,
        width: spacing.unit * 2.25,
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    }
});

const SearchAllDataToggle = withStyles(styles)(
    ({ classes: c, checked, onChange }) => {
        return (
            <div className={c.root}>
                <Switch
                    checked={checked}
                    onChange={() => onChange(!checked)}
                    className={c.switch}
                    color='primary'
                />

                <Typography className={c.label}>Search in all data</Typography>

                <Tooltip
                    title='By default, the system will search data from the last two years. Check this box to search in all data.'
                    placement='right'
                >
                    <InfoOutlined className={c.infoIcon} />
                </Tooltip>
            </div>
        );
    }
);

export default SearchAllDataToggle;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired
};

const defaultProps = {
    title: 'Modal Title'
};

const styles = ({ spacing, palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: palette.background.light,
        padding: spacing.unit,
        paddingLeft: spacing.unit * 5,
        paddingRight: spacing.unit * 5
    },
    closeButton: {
        marginLeft: 'auto'
    }
});

const PortalModalHeader = ({ classes: c, onClose, children, className }) => {
    return (
        <div aria-label='Modal Header' className={clsx(c.root, className)}>
            <Typography variant='h6' aria-label='Modal Title'>
                {children}
            </Typography>
            <IconButton
                aria-label='Close'
                className={c.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
        </div>
    );
};

PortalModalHeader.propTypes = propTypes;

PortalModalHeader.defaultProps = defaultProps;

const _PortalModalHeader = withStyles(styles)(PortalModalHeader);

export {
    _PortalModalHeader as default,
    _PortalModalHeader as PortalModalHeader
};

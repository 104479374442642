import React from 'react';
import { string } from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import { Image, withPDFStyles } from '@libComponents/ReactPDF';

const propTypes = {
    url: string,
    label: string
};

const defaultProps = {
    label: 'Image'
};

const styles = ({ spacing }) => ({
    frameWrapper: {
        width: '48%',
        border: spacing.unit * 0.125,
        borderColor: '#c8ced4',
        flexDirection: 'column',
        minHeight: spacing.unit * 18.75,
        marginBottom: spacing.unit * 1.25
    },
    label: {
        width: '100%',
        height: spacing.unit * 5.5,
        flexDirection: 'row',
        padding: `${spacing.unit}px ${spacing.unit}px ${
            spacing.unit
        }px ${spacing.unit * 3}px`,
        alignItems: 'center',
        backgroundColor: '#eff3f6',
        borderBottom: spacing.unit * 0.125,
        borderBottomColor: '#c8ced4'
    },
    image: {
        width: 'auto',
        objectFit: 'contain'
    }
});

const Frame = withPDFStyles(styles)(({ classes: c, url, label }) => (
    <View style={c.frameWrapper} wrap={false}>
        <Text style={c.label}>{label}</Text>
        {url && <Image src={url} style={c.image} />}
    </View>
));

Frame.propTypes = propTypes;
Frame.defaultProps = defaultProps;

export { Frame as default, Frame };

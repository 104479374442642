import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import clsx from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';

const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    children: PropTypes.node.isRequired
};

const defaultProps = {
    size: 'md'
};

const styles = ({ shadows, shape, palette, breakpoints }) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9998
    },
    root: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: palette.background.paper,
        boxShadow: shadows[5],
        borderRadius: shape.borderRadius,
        outline: 'none',
        zIndex: 9999
    },
    sm: {
        width: '50%',
        maxWidth: 700,
        [breakpoints.down(600)]: {
            width: '90%'
        }
    },
    md: {
        width: '70%',
        maxWidth: 1000,
        [breakpoints.down(600)]: {
            width: '90%'
        }
    },
    lg: {
        width: '90%',
        maxWidth: 1200,
        [breakpoints.down(600)]: {
            width: '90%'
        }
    }
});

const PortalModalContainer = ({ classes: c, isOpen, size, children }) => {
    if (!isOpen) return null;

    return createPortal(
        <div className={c.overlay}>
            <Paper className={clsx(c.root, c[size] && c[size])}>
                {children}
            </Paper>
        </div>,
        document.getElementById('echo-modal')
    );
};

PortalModalContainer.propTypes = propTypes;

PortalModalContainer.defaultProps = defaultProps;

const _PortalModalContainer = withStyles(styles)(PortalModalContainer);

export {
    _PortalModalContainer as default,
    _PortalModalContainer as PortalModalContainer
};

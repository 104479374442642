import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    children: PropTypes.node.isRequired
};

const styles = ({ spacing }) => ({
    root: {
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2,
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 2
    }
});

const PortalModalContent = ({ classes: c, children, className }) => {
    return (
        <div aria-label='Modal Content' className={clsx(c.root, className)}>
            {children}
        </div>
    );
};

PortalModalContent.propTypes = propTypes;

const _PortalModalContent = withStyles(styles)(PortalModalContent);

export {
    _PortalModalContent as default,
    _PortalModalContent as PortalModalContent
};

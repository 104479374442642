import axios from 'axios';
import { call } from 'redux-saga/effects';

import { getCookie } from '@libs/cookies';
import { ACCESS_TOKEN_KEY } from 'auth/constant';

import config from './config';
import uploadProgress from './uploadProgress';
import transformIntoFormData from './transformIntoFormData';

const apiClient = axios.create(config);

function* apiRequest({
    type,
    transformData = false,
    uploadToken,
    withAuthToken = true,
    ...rest
}) {
    const AUTH_TOKEN = getCookie(ACCESS_TOKEN_KEY);

    if (withAuthToken)
        apiClient.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${AUTH_TOKEN}`;

    const loadedConfig = {
        ...rest,
        transformRequest: transformData ? transformIntoFormData : null,
        onUploadProgress: uploadToken
            ? yield* uploadProgress(uploadToken)
            : null,
        ...(transformData ? { originalData: rest.data } : {}) // In the interceptor we can access the original data and transform it.
    };

    return yield call(apiClient, loadedConfig);
}

export { apiRequest as default, apiRequest, apiClient };

import { formValueSelector } from 'redux-form';
import { select, call, all } from 'redux-saga/effects';

import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';
import { sizePathsSelector } from 'common/redux.selectors';
import { syncReportToDatabase } from 'common/redux.sagas';

import { FORM_NAME } from 'orders/Order/common';

export default function*({ echoOrderNo, orderVersionNo, mode, handleLoading }) {
    const { pdf } = yield select(formValueSelector(FORM_NAME), 'mailData');

    const [reportBlobs, reportSizePaths] = yield all([
        call(getAssetBlobsWorker, {
            assets: pdf,
            mode,
            handleLoading
        }),
        select(sizePathsSelector, 'reportPath')
    ]);

    const [reportPayload] = yield call(uploadAssetsWorker, {
        assetBlobs: reportBlobs,
        folderPath: `${reportSizePaths.sizeOriginal}/${echoOrderNo}`,
        reference: echoOrderNo,
        sizePaths: reportSizePaths,
        handleLoading,
        developmentName: `V${orderVersionNo}`
    });

    const originalPath = reportPayload ? reportPayload.sizeOriginal : '';

    return yield call(syncReportToDatabase, {
        reportReference: echoOrderNo,
        reportUrl: originalPath,
        referenceDevNo: orderVersionNo,
        handleLoading
    });
}

import React, { Fragment } from 'react';

const dataGridDefaultRootProps = {
    columns: [],
    data: [],
    dataGridLoading: false,
    disableCheckboxColumn: false,
    disableColumnResize: false,
    disableInlineEdit: false,
    disableFieldFilter: false,
    storedDataGridSettings: {},
    setDataGridSettings: p => p,
    fetchGridData: f => f,
    syncGridDataWithServerAsync: data =>
        new Promise(resolve => resolve([data])),
    RenderDataGridExportOptions: () => <Fragment />,
    datasetSelector: props => [],
    datasetLoadingSelector: props => false,
    valueSelector: (domain, entity, id) => id,
    rowStyle: () => null,
    isRowEditable: () => true,
    rowHeight: 40, // Should be changed for row with image.
    pageSizes: [10, 25, 50, 100],
    enableAllDataSearch: false
};

export { dataGridDefaultRootProps as default, dataGridDefaultRootProps };

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    children: PropTypes.node.isRequired
};

const styles = ({ spacing }) => ({
    root: {
        padding: spacing.unit * 2
    }
});

const PortalModalFooter = ({ classes: c, children, className }) => {
    return (
        <div aria-label='Modal Footer' className={clsx(c.root, className)}>
            {children}
        </div>
    );
};

PortalModalFooter.propTypes = propTypes;

const _PortalModalFooter = withStyles(styles)(PortalModalFooter);

export {
    _PortalModalFooter as default,
    _PortalModalFooter as PortalModalFooter
};

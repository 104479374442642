import { formValueSelector } from 'redux-form';
import { select, call, all } from 'redux-saga/effects';

import { userNameSelector, userEmailSelector } from 'auth/redux.selectors';
import { getFileNameFromURL } from '@libs/filenames';
import composeApiPayload from '@libs/composeApiPayload';
import { apiRequest } from '@libs/apiRequest';
import { updateCcAddress } from '@libs/mailer';

import { FORM_NAME } from 'orders/Order/common';
import { orderEmailSchema } from './schemas';

export default function* sendOrderEmail({
    echoOrderNo,
    PDFReportURL,
    handleLoading
}) {
    // Retrieve form values for email details
    const { receivers: toAddress, cc: ccAddress, subject, body } = yield select(
        formValueSelector(FORM_NAME),
        'mailData'
    );

    // Fetch user details
    const [userEmail, userName] = yield all([
        select(userEmailSelector),
        select(userNameSelector)
    ]);

    // Get the file name from the URL
    const fileName = yield call(getFileNameFromURL, PDFReportURL);

    // Compose the email body with the PDF link
    const composedBody = `
        ${body}
        <br/>
        <a style="color: #234c72; margin-right: 10px; font-size: 16px;" href="${PDFReportURL}">${fileName}</a>`;

    // Clean up the toAddress string
    const recipients = toAddress.replace(/\s/g, '');

    // Update the CC address list
    const updatedCcAddress = updateCcAddress(ccAddress, userEmail, recipients);

    // Compose the API payload for the email
    const emailPayload = composeApiPayload(
        {
            userName,
            toAddress: recipients,
            ccAddress: updatedCcAddress,
            subject,
            body: composedBody,
            echoOrderNo
        },
        orderEmailSchema
    );

    // Make the API request to send the email
    yield call(apiRequest, {
        method: 'post',
        url: 'EmailSend/OrderEmailSend',
        data: emailPayload
    });

    yield call(handleLoading);
}

import handleJWT from '@libs/handleJWT';
import { SET_AUTH_DATA } from 'auth/redux.actionTypes';
import { logout } from 'auth/redux.actions';
import { getCookie } from '@libs/cookies';
import { REFRESH_TOKEN_KEY } from 'auth/constant';

import { apiClient } from '.';
import { UNAUTHENTICATED_CODE } from './httpStatusCodes';
import transformIntoFormData from './transformIntoFormData';

const errorInterceptorHandler = store => {
    const nonAuthenticatedEndpoints = [
        'User/GetUserByLogin',
        'User/DoubleAuthenticationandSendEmail',
        'User/DoubleAuthenticationCheck',
        'User/ForgetPasswordandSendEmail',
        'User/ChangePasswordandLogin',
        'User/RefreshToken'
    ];
    const { _, dispatch } = store;
    let pendingRefreshRequests = [];
    let isRefreshing = false;

    const handleTokenRefresh = (originalConfig, err) => {
        if (originalConfig._retry) {
            return;
        }
        originalConfig._retry = true;

        if (!isRefreshing) {
            isRefreshing = true;
            const refreshToken = getCookie(REFRESH_TOKEN_KEY);
            const payload = JSON.stringify({ refreshToken });

            apiClient
                .post('User/RefreshToken', payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(({ data: { data } = {} }) => {
                    const newAccessToken = data.accessToken;
                    dispatch({
                        type: SET_AUTH_DATA,
                        payload: handleJWT(data)
                    });
                    pendingRefreshRequests.forEach(cb => cb(newAccessToken));
                })
                .catch(error => {
                    dispatch(logout());
                })
                .finally(() => {
                    pendingRefreshRequests = [];
                    isRefreshing = false;
                });
        }

        // Return a promise that resolves with the request using the new token
        return new Promise((resolve, reject) => {
            pendingRefreshRequests.push(token => {
                if (token) {
                    originalConfig.headers['Authorization'] = `Bearer ${token}`;

                    // If the data is a FormData, then transformRequest needs to be updated
                    if (originalConfig.data instanceof FormData) {
                        originalConfig.transformRequest = transformIntoFormData(
                            originalConfig.originalData
                        );
                    }

                    resolve(apiClient(originalConfig));
                } else {
                    reject(err);
                }
            });
        });
    };

    return error => {
        const {
            config,
            response: { status }
        } = error;
        const originalRequest = config;

        const isNonAuthenticatedEndpoint = nonAuthenticatedEndpoints.some(
            endpoint => originalRequest.url.includes(endpoint)
        );

        if (status === UNAUTHENTICATED_CODE && !isNonAuthenticatedEndpoint) {
            return handleTokenRefresh(originalRequest, error);
        }

        return Promise.reject(error);
    };
};

const setupInterceptors = store => {
    apiClient.interceptors.response.use(
        response => response,
        errorInterceptorHandler(store)
    );
};

export default setupInterceptors;

import { useState, useCallback, useMemo, useEffect } from 'react';

const usePagination = (
    {
        pageNumber = 1,
        pageSize: pageWeight = 10,
        totalPages,
        ...restPagination
    },
    filterValues,
    searchAllData
) => {
    const SIBLING_COUNT = 2;
    const DOTS = '...';

    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [pageSize, setPageSize] = useState(pageWeight);

    const handlePageSize = useCallback(pageSize => {
        setPageSize(pageSize);
        setCurrentPage(1);
    }, []);

    const goToPage = useCallback(page => {
        setCurrentPage(page);
    }, []);

    const goToFirstPage = useCallback(() => {
        setCurrentPage(1);
    }, []);

    const goToLastPage = useCallback(() => {
        setCurrentPage(totalPages);
    }, [totalPages]);

    const goToPrevPage = useCallback(() => {
        setCurrentPage(prevPage => prevPage - 1);
    }, [currentPage]);

    const goToNextPage = useCallback(() => {
        setCurrentPage(prevPage => prevPage + 1);
    }, [currentPage]);

    const paginationRange = useMemo(() => {
        const totalSiblingCount = SIBLING_COUNT * 2;
        const expectedTotalPageCount = totalSiblingCount + 3; // first page, last page, and 1 page on either side of the current page
        const totalPageCount = Math.min(expectedTotalPageCount, totalPages); // actual totalPages when it's less than expectedTotalPageCount
        const startPage = Math.max(1, currentPage - SIBLING_COUNT);
        const endPage = Math.min(totalPages, currentPage + SIBLING_COUNT);

        let pages = [...Array(endPage - startPage + 1)].map(
            (_, i) => startPage + i
        );
        const hasLeftSpill = startPage > 1;
        const hasRightSpill = endPage < totalPages;
        const spillOffset = totalPageCount - (pages.length + 2); // account for first page and last page

        if (hasLeftSpill && !hasRightSpill) {
            const extraPages = [...Array(Math.max(spillOffset, 0))].map(
                (_, i) => startPage - (i + 1)
            );
            pages = [DOTS, ...extraPages, ...pages];
        } else if (!hasLeftSpill && hasRightSpill) {
            const extraPages = [...Array(Math.max(spillOffset, 0))].map(
                (_, i) => endPage + i + 1
            );
            pages = [...pages, ...extraPages, DOTS];
        } else if (hasLeftSpill && hasRightSpill) {
            const leftSpillCount = Math.ceil(spillOffset / 2);
            const rightSpillCount = spillOffset - leftSpillCount;
            const extraLeftPages = [...Array(Math.max(leftSpillCount, 0))].map(
                (_, i) => startPage - (i + 1)
            );
            const extraRightPages = [
                ...Array(Math.max(rightSpillCount, 0))
            ].map((_, i) => endPage + i + 1);
            pages = [
                DOTS,
                ...extraLeftPages,
                ...pages,
                ...extraRightPages,
                DOTS
            ];
        }
        return pages;
    }, [currentPage, totalPages]);

    useEffect(() => {
        setCurrentPage(1);
    }, [JSON.stringify(filterValues), searchAllData]);

    return {
        currentPage,
        totalPages,
        pageSize,
        setPageSize: handlePageSize,
        goToPage,
        goToFirstPage,
        goToLastPage,
        goToPrevPage,
        goToNextPage,
        paginationRange,
        DOTS,
        ...restPagination
    };
};

export default usePagination;

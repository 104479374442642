import { fork, call, takeLatest, select, put } from 'redux-saga/effects';
import { formValueSelector, change } from 'redux-form';

import { PRIMARK_ID, NEWLOOK_ID } from 'orders/common';

import { emailSchema, reportSchema } from './schemas';
import { apiRequest } from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import { SET_GENDER_CHILDREN } from '../redux.actionTypes';

// Workers
export const syncReportToDatabase = function*({
    handleLoading = f => f,
    ...payload
}) {
    const reportData = composeApiPayload(payload, reportSchema);
    const {
        data: { reportUrl }
    } = yield call(apiRequest, {
        method: 'post',
        url: 'Reports',
        data: reportData
    });
    yield call(handleLoading);
    return reportUrl;
};

export const emailSend = function*(payload) {
    const emailData = composeApiPayload(payload, emailSchema);
    yield fork(apiRequest, {
        method: 'post',
        url: 'EmailSend/SampleEmailSend',
        data: emailData
    });
};

function* setGenderChildrenWorker({ payload: genderId, meta: { formName } }) {
    const retailer = yield select(formValueSelector(formName), 'retailer');
    const NOT_APPLICABLE_AGE_ID = 4;
    const AGE_ID_FOR_915 = 5;
    const BOYS_GENDER_ID = 3;
    const GIRLS_GENDER_ID = 4;

    yield put(change(formName, 'age', ''));
    yield put(change(formName, 'department', ''));

    if (
        retailer === PRIMARK_ID &&
        [BOYS_GENDER_ID, GIRLS_GENDER_ID].includes(genderId)
    ) {
        return;
    }

    if (retailer === NEWLOOK_ID && GIRLS_GENDER_ID === genderId) {
        yield put(change(formName, 'department', 3));
        yield put(change(formName, 'age', AGE_ID_FOR_915));
        return;
    }

    yield put(change(formName, 'age', NOT_APPLICABLE_AGE_ID));
}

// Watchers
export default function*() {
    yield takeLatest(SET_GENDER_CHILDREN, setGenderChildrenWorker);
}

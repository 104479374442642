import { formValueSelector } from 'redux-form';
import { select, all, call, put } from 'redux-saga/effects';
// Libs
import { apiRequest } from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import {
    publishFailedWorker,
    getAssetBlobsWorker,
    uploadAssetsWorker,
    composeLoadingWorker
} from '@libs/publishWorkers';
import { sizePathsSelector } from 'common/redux.selectors';
import { setSnack } from 'utilities/redux.actions';
// Local
import { FORM_NAME } from '../../../Order/common';
import getPublishPayloadWorker from './getPublishPayloadWorker';
import savedDraftWorker from './savedDraftWorker';
import softPublishWorker from './softPublishWorker';
import { publishSchema } from './schemas';
import statusChangeWorker from './statusChangeWorker';
import PDFReportFileUpload from './PDFReportFileUploadWorker';
import sendOrderEmailWorker from './sendOrderEmailWorker';

export default function* publishOrder(action) {
    const {
        meta: {
            handleComplete = () => {},
            saveDraft = false,
            isHold = false,
            isCancel = false,
            softPublish = false
        },
        payload: {
            mode,
            closePreviewModal = () => {},
            closeMailModal = () => {}
        }
    } = action;

    const { attachments = [], echoOrderNo, versionNo } = yield select(
        formValueSelector(FORM_NAME),
        'attachments',
        'echoOrderNo',
        'versionNo'
    );

    const handleLoading = yield call(composeLoadingWorker, {
        assets: [...attachments],
        mode
    });

    try {
        const [attachmentBlobs, attachmentSizePaths] = yield all([
            call(getAssetBlobsWorker, {
                assets: attachments,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'orderAttachment')
        ]);

        const orderVersionNo =
            mode === 'amend' && !softPublish
                ? Number(versionNo) + 1
                : versionNo;

        const attachmentsPayload = yield call(uploadAssetsWorker, {
            assetBlobs: attachmentBlobs,
            folderPath: `${attachmentSizePaths.sizeOriginal}/${echoOrderNo}`,
            reference: echoOrderNo,
            sizePaths: attachmentSizePaths,
            handleLoading,
            developmentName: orderVersionNo,
            enableSerial: true
        });

        let PDFReportURL = '';
        if (!saveDraft && !softPublish) {
            PDFReportURL = yield call(PDFReportFileUpload, {
                echoOrderNo,
                orderVersionNo,
                mode,
                handleLoading
            });
        }

        const payload = yield call(getPublishPayloadWorker);

        const publishData = composeApiPayload(
            {
                ...payload,
                isDiscontinued: !saveDraft,
                isCancel,
                isHold,
                softPublish,
                attachments: attachmentsPayload,
                orderVersionNo
            },
            publishSchema
        );

        yield call(apiRequest, {
            method: 'put',
            url: 'OrderMains/PublishOrder',
            data: publishData
        });

        if (!saveDraft && !softPublish) {
            try {
                yield call(sendOrderEmailWorker, {
                    echoOrderNo,
                    PDFReportURL,
                    handleLoading
                });
            } catch (emailError) {
                // Notify user that publishing was successful, but email sending failed
                yield put(
                    setSnack({
                        message:
                            'Data has been published, but sending the email failed. Please try again.',
                        type: 'error',
                        duration: 10000,
                        action: {
                            label: 'Retry',
                            handle: action
                        }
                    })
                );
                yield call(handleLoading, true);
                return;
            }
        }

        if (saveDraft) {
            yield call(savedDraftWorker, { mode, echoOrderNo });
        }

        if (softPublish) {
            yield call(softPublishWorker, { echoOrderNo });
        }

        closeMailModal();
        closePreviewModal();

        if (isCancel || isHold) {
            yield call(statusChangeWorker, {
                echoOrderNo,
                statusText: isHold ? 'held' : 'canceled'
            });
        }

        yield call(handleLoading, true);
        return handleComplete();
    } catch (error) {
        const actionType = saveDraft
            ? 'save'
            : isCancel
            ? 'cancel'
            : isHold
            ? 'hold'
            : softPublish
            ? 'soft publish'
            : 'publish';

        const actionErrorMessage = `Failed to ${actionType}`;

        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage,
            error
        });
    }
}

import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Material
import { Button, Typography, withStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
    PortalModalContainer,
    PortalModalContent,
    PortalModalHeader
} from '@libComponents/PortalModal';

const propTypes = {
    url: (props, propName, componentName) => {
        if (!props.url && !props.loading) {
            return new Error(
                `The prop '${propName}' is required in '${componentName}'.`
            );
        }
    },
    name: PropTypes.string.isRequired,
    showIcon: PropTypes.bool,
    loading: PropTypes.bool
};

const defaultProps = {
    loading: false,
    showIcon: true
};

const styles = ({ palette, spacing, shape }) => ({
    root: {
        display: 'flex',
        height: spacing.unit * 2.5,
        color: palette.primary.main,
        textTransform: 'none',
        padding: 0,
        paddingRight: spacing.unit * 0.5,
        borderRadius: shape.borderRadius * 0.5,

        '&:hover': {
            color: palette.primary.dark,
            cursor: 'pointer'
        }
    },
    icon: {
        color: 'inherit',
        fontSize: spacing.unit * 2.5,
        marginRight: spacing.unit * 0.5
    },
    label: {
        height: '100%',
        lineHeight: '100%',
        color: 'inherit'
    },
    iframe: {
        width: '100%',
        height: '80vh',
        border: 'none'
    },
    imgWrapper: {
        width: '100%',
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    }
});

const PreviewButton = withStyles(styles, { withTheme: true })(
    ({ classes: c, url, name, loading, showIcon, ...props }) => {
        const [previewOpen, setPreviewOpen] = useState(false);

        const handlePreview = useCallback(() => {
            setPreviewOpen(true);
        }, []);

        const handleClosePreview = useCallback(() => {
            setPreviewOpen(false);
        }, []);

        const isXlsxFile = name && name.endsWith('.xlsx');
        const isImageFile = name && /\.(jpg|jpeg|png|gif)$/i.test(name);

        return (
            <Fragment>
                <Button
                    className={c.root}
                    {...props}
                    onClick={handlePreview}
                    disabled={loading || isXlsxFile}
                >
                    {showIcon && <VisibilityIcon className={c.icon} />}
                    <Typography variant='caption' className={c.label}>
                        Preview
                    </Typography>
                </Button>
                <PortalModalContainer isOpen={previewOpen}>
                    <PortalModalHeader onClose={handleClosePreview}>
                        {name}
                    </PortalModalHeader>
                    <PortalModalContent>
                        {url &&
                            (isImageFile ? (
                                <div className={c.imgWrapper}>
                                    <img
                                        src={url}
                                        alt={name}
                                        className={c.img}
                                    />
                                </div>
                            ) : (
                                <iframe
                                    src={url}
                                    className={c.iframe}
                                    title={name}
                                />
                            ))}
                    </PortalModalContent>
                </PortalModalContainer>
            </Fragment>
        );
    }
);

PreviewButton.propTypes = propTypes;
PreviewButton.defaultProps = defaultProps;

export { PreviewButton as default, PreviewButton };

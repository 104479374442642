// Transforms object into FormData
// Todo: Make usable for nested objects
// Nested append uses key.key for keys
// take into account recursion
const transformIntoFormData = data =>
    Object.entries(data).reduce((formData, [key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(data => formData.append(key, data));
        } else {
            formData.append(key, value);
        }
        return formData;
    }, new FormData());

export default transformIntoFormData;
